/* eslint-disable */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['realtorForm', 'realtorIpAddress', 'headOfficeId', 'branchId', 'branchAddInfos', 'branchIdAddRealtorId'];

  destroyMainPhoto() {
    const photo = document.getElementById('realtor_main_photo');
    const photo_hidden = document.getElementById('realtor_main_photo_hidden');
    photo.value = '';
    photo_hidden.value = '';
    photo_hidden.closest('.m-file-input__preview').innerHTML = 'プレビュー';
  }

  destroyLogoPhoto() {
    const photo = document.getElementById('realtor_logo_photo');
    const photo_hidden = document.getElementById('realtor_logo_photo_hidden');
    photo.value = '';
    photo_hidden.value = '';
    photo_hidden.closest('.m-file-input__preview').innerHTML = 'プレビュー';
  }

  toggleHeadOffice(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      this.headOfficeIdTarget.disabled = true;
      this.headOfficeIdTarget.value = null;
      this.branchIdTarget.disabled = true;
      this.branchIdTarget.value = null;
      this.branchAddInfosTarget.disabled = true;
      this.branchAddInfosTarget.closest('label').classList.remove('is-checked');
      this.branchIdAddRealtorIdTarget.disabled = true;
      this.branchIdAddRealtorIdTarget.closest('label').classList.remove('is-checked');
    } else {
      this.branchAddInfosTarget.disabled = false;
    }
  }

  toggleBranchAddInfos(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      this.headOfficeIdTarget.disabled = false;
      this.branchIdTarget.disabled = false;
      this.branchIdAddRealtorIdTarget.disabled = false;
    } else {
      this.headOfficeIdTarget.disabled = true;
      this.headOfficeIdTarget.value = null;
      this.branchIdTarget.disabled = true;
      this.branchIdTarget.value = null;
      this.branchIdAddRealtorIdTarget.disabled = true;
      this.branchIdAddRealtorIdTarget.closest('label').classList.remove('is-checked');
    }
  }

  toggleBranchIdAddRealtorId(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      this.branchIdTarget.value = this.realtorId;
    } else {
      this.branchIdTarget.value = null;
    }
  }

  validateForm(event) {
    const tmpIps = this.realtorIpAddressTargets.map((ip) => ip.value);
    const ips = tmpIps.filter(item => item !== '' && item !== null);

    if (ips.length > 0 && !ips.includes(this.currentIpAddress)) {
      if (!confirm('「現在アクセス中のIPアドレス」が設定されていないため、現在の環境からでは接続できなくなります\n\n本当によろしいですか？')) {
        event.preventDefault();
      }
    }
  }

  get currentIpAddress() {
    return this.data.get('current-ip-address');
  }

  get realtorId() {
    return this.data.get('realtor-id');
  }
}
